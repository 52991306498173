import React, { useContext } from "react";
import { Link } from "gatsby";
import { Row, Col, Container } from "react-bootstrap";
import PageWrapper from "../../components/PageWrapper";
import SEO from "../../components/Layout/SEO";
import SuccessStories from "../../sections/camelo/SuccessStories";

import bgSection from "../../assets/image/customers/imc_story_large.png";

const MiddleCTA = () => {
  return (
    <Row className="bg-default-1 border-top">
      <Container>
        <Col xs="12" className="text-center pb-24">
          <h2 className="gr-text-5 pt-24 pb-8">
            <span
              className="highlighted1"
              style={{ backgroundPosition: "0 20px" }}
            >
              Try Camelo now and see the difference yourself.
            </span>
          </h2>
          <div>
            <Link
              to={process.env.SIGNUP_URL}
              target="__blank"
              className="btn btn-primary with-icon gr-hover-y"
            >
              Start scheduling free
              <i className="icon icon-tail-right font-weight-bold"></i>
            </Link>
            <p className="gr-text-12 pt-8">
              59 companies signed up in the last week alone!
            </p>
          </div>
        </Col>
      </Container>
    </Row>
  );
};

const NailStory = () => {
  return (
    <>
      <PageWrapper>
        <SEO
          title="How Camelo improves the scheduling process in IMC"
          description="Find out how the Camelo employee scheduling app transforms shift scheduling, timesheets, & communication for Intercon Management Consulting."
        ></SEO>
        <div className="inner-banner bg-default-6 pt-24 pt-lg-30 pb-lg-15">
          <Container>
            <Row className="justify-content-center pt-5">
              <Col xl="8" lg="9">
                <div className="px-md-12 mb-13 text-center">
                  <h1 className="title gr-text-4 mb-8 mb-lg-12">
                    How Camelo improves the scheduling process in IMC
                  </h1>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div
          className="video-section bg-image py-28 py-lg-35 dark-mode-texts "
          css={`
            background-image: url(${bgSection});
          `}
        />

        <div className="pb-2 pb-lg-9 pt-13 pt-lg-24 bg-default-6 customer-story">
          <Container>
            <Row className="justify-content-center">
              <Col xl="10" lg="10">
                <div className="px-md-12 text-black mb-13 mb-lg-22">
                  <p className="gr-text-8 text-black">
                    <Link to="https://www.interconmc.com/" target="__blank">
                      {`Intercon Management Consulting (IMC)  `}
                    </Link>
                    is a consulting firm that specializes in providing practical
                    solutions to companies and organizations in various areas,
                    including stakeholder engagement, networking, and event and
                    talent management. Like many consulting firms, IMC faced
                    numerous challenges in effectively managing their employees
                    and streamlining their scheduling process. However, thanks
                    to the discovery of the Camelo app, IMC's Manager, Ricardo,
                    witnessed a remarkable improvement in their shift scheduling
                    operations.
                  </p>
                  <h4 className="pt-8 pb-4">Challenges of a consulting firm</h4>
                  <p className="gr-text-8 text-black">
                    Consulting firms face various challenges in their
                    operations. Gaining and building trust with clients while
                    balancing workloads can be tough. Standing out in a
                    competitive market requires constant innovation and
                    improvements.
                  </p>
                  <p className="gr-text-8 text-black">
                    Ricardo, the Manager of IMC, considered employee management
                    one of the toughest aspects of running a consulting firm.
                  </p>
                  <blockquote className="blockquote mb-0 card-body">
                    <p className="gr-text-8 text-black">
                      "Each employee has their own needs, preferences, and
                      opinions. Managing employees requires lots of balancing,
                      communication, and patience."
                    </p>
                  </blockquote>

                  <h4 className="pt-8 pb-4">
                    The search for an efficient scheduling solution
                  </h4>
                  <p className="gr-text-8 text-black">
                    IMC operates with employees working in shifts, making a
                    reliable and efficient shift scheduling system crucial. In
                    an attempt to find a suitable solution, Ricardo extensively
                    explored various options, including spreadsheets and random
                    scheduling apps.
                  </p>
                  <p className="gr-text-8 text-black">
                    Unfortunately, none of them proved effective. Some solutions
                    demanded excessive customization and proved challenging for
                    employees to navigate. Additionally, communicating important
                    scheduling information became a laborious task, resulting in
                    frequent miscommunication, confusion, and unnecessary
                    delays.
                  </p>
                  <p className="gr-text-8 text-black">
                    The struggle to strike a balance between meeting operational
                    demands and considering factors such as availability,
                    preferences, and fairness for all team members was a
                    persistent challenge for Ricardo and IMC.
                  </p>
                  <blockquote className="blockquote mb-0 card-body">
                    <p className="gr-text-8 text-black">
                      "It was hard to balance between meeting operational
                      demands and considering availability, preferences, and
                      fairness for all team members."
                    </p>
                  </blockquote>

                  <h4 className="pt-8 pb-4">Scheduling has become a breeze</h4>
                  <p className="gr-text-8 text-black">
                    Ricardo stumbled upon the Camelo app while searching on the
                    web. Impressed with the user-friendly interface and robust
                    features of Camelo, he decided to use it for a while to
                    explore all its capabilities.
                  </p>
                  <p className="gr-text-8 text-black">
                    Creating schedules has become a breeze. Ricardo can drag and
                    drop on the web app to schedule shifts. When he’s outside,
                    he can use the smartphone app to do the scheduling. The
                    smart algorithms on Camelo help him assign shifts while
                    considering employee availability, skills, and preferences.
                  </p>
                  <blockquote className="blockquote mb-0 card-body">
                    <p className="gr-text-8 text-black">
                      "Camelo has changed our shift scheduling process for the
                      better. It's like having a dedicated scheduling assistant
                      that never makes mistakes. Thanks to Camelo, we have saved
                      many hours every week."
                    </p>
                  </blockquote>

                  <h4 className="pt-8 pb-4">
                    Communication is definitely improved
                  </h4>
                  <p className="gr-text-8 text-black">
                    In addition to seamless scheduling, Camelo also streamlines
                    communication between management and employees in IMC,
                    eliminating the need for multiple back-and-forth exchanges.
                  </p>
                  <p className="gr-text-8 text-black">
                    Ricardo can share critical updates, shift changes, and
                    reminders with individuals and the entire team through
                    Camelo's notification and messaging features.
                  </p>
                  <blockquote className="blockquote mb-0 card-body">
                    <p className="gr-text-8 text-black">
                      "Communication with our employees is so easy now. Camelo
                      helps me keep my team well-informed and updated."{" "}
                    </p>
                  </blockquote>

                  <h4 className="pt-8 pb-4">
                    From chaos to seamless operations
                  </h4>
                  <p className="gr-text-8 text-black">
                    Camelo's automated features and time-saving benefits have
                    not only improved the operations at IMC but also enabled the
                    company to allocate more time and attention to delivering
                    thoughtful guidance and recommendations to their valued
                    clients.
                  </p>
                  <p className="gr-text-8 text-black">
                    The remarkable impact of the app's functionality has left
                    Ricardo and the entire IMC team highly satisfied, leading
                    them to make a resolute decision to continue utilizing
                    Camelo for the long term. By solidifying their commitment to
                    the app, IMC can confidently rely on its capabilities to
                    streamline their scheduling process and focus on providing
                    exceptional consulting services without any hesitation.
                  </p>
                  <blockquote className="blockquote card-body">
                    <p className="text-black pt-4 gr-text-6 lead">
                      "Camelo has become an invaluable tool for Intercon
                      Management Consulting. We can now focus more on delivering
                      exceptional consulting services rather than getting caught
                      up in scheduling conflicts. I don’t see the need to look
                      elsewhere for another solution anymore."
                    </p>
                  </blockquote>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <MiddleCTA />
        <SuccessStories />
      </PageWrapper>
    </>
  );
};
export default NailStory;
